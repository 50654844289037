import { useEffect, useState } from 'react';
import { Grid, TextareaAutosize, Button, Typography } from '@mui/material';
import useAuth from './hooks/useAuth';

function Admin() {
  const { getToken } = useAuth();
  const [clients, setClients] = useState([])
  const [activities, setActivities] = useState([]);
  const [newClientsText, setNewClientsText] = useState('');
  const [newActivitiesText, setNewActivitiesText] = useState('');
  const [loading, setLoading] = useState(false);

  async function fetchClients() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'GET',
        headers
      });
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchActivities() {
    try {
      let url = `${process.env.REACT_APP_URL}/api/activities`;
      const token = await getToken();

      const headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const result = await fetch(url, {
        method: 'GET',
        headers
      });
      if (result.ok) {
        const data = await result.json();
        setActivities(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addClients() {
    console.log(newClientsText)
    if (!newClientsText.trim()) return; // No action if input is empty

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/clients`;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ textInput: newClientsText }),
      });

      if (response.ok) {
        setNewClientsText(''); // Clear input on success
        fetchClients(); // Refresh clients list
      } else {
        console.error('Failed to add clients');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  
  async function addActivities() {
    console.log(newActivitiesText)
    if (!newActivitiesText.trim()) return; // No action if input is empty

    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/api/activities`;
      const token = await getToken();

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ textInput: newActivitiesText }),
      });

      if (response.ok) {
        setNewActivitiesText(''); // Clear input on success
        fetchActivities(); // Refresh activities list
      } else {
        console.error('Failed to add activities');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    fetchActivities();
    fetchClients();
  }, []);


  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Add New Clients</Typography>
          <TextareaAutosize
            minRows={6}
            placeholder="Enter client names, one per line..."
            value={newClientsText}
            onChange={(e) => setNewClientsText(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '16px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addClients}
            disabled={loading}
            style={{ marginTop: '10px' }}
          >
            {loading ? 'Adding...' : 'Add Clients'}
          </Button>
        </Grid>

        {/* Right side: Display existing clients */}
        <Grid item xs={12} md={3} pb={30}>
          <Typography variant="h6">Existing Clients</Typography>
          <TextareaAutosize
            minRows={10}
            maxRows={10}
            value={clients.map((client) => client.client_name).join('\n')}
            readOnly
            style={{
              width: '100%',
              height: '30px',
              overflowY: 'scroll',
              fontSize: '16px',
              backgroundColor: '#f5f5f5',
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: '100%'}}></Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="h6">Add New Activities</Typography>
          <TextareaAutosize
            minRows={6}
            placeholder="Enter activity names, one per line..."
            value={newActivitiesText}
            onChange={(e) => setNewActivitiesText(e.target.value)}
            style={{ width: '100%', padding: '10px', fontSize: '16px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addActivities}
            disabled={loading}
            style={{ marginTop: '10px' }}
          >
            {loading ? 'Adding...' : 'Add Activities'}
          </Button>
        </Grid>

        {/* Right side: Display existing activities */}
        <Grid item xs={12} md={3} pb={30}>
          <Typography variant="h6">Existing Activities</Typography>
          <TextareaAutosize
            minRows={10}
            maxRows={10}
            value={activities.map((activity) => activity.activity).join('\n')}
            readOnly
            style={{
              width: '100%',
              height: '30px',
              overflowY: 'scroll',
              fontSize: '16px',
              backgroundColor: '#f5f5f5',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Admin;
