import { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Grid } from '@mui/material';

const DataTable = ({ data, editItem = null, againItem = null, localeFormat, exportButton = null }) => {
  const getDuration = (seconds) => {
    dayjs.extend(duration);
    const dur = dayjs.duration(seconds, 'seconds');
    // Format the duration as a time string
    const formattedTime = `${dur.hours().toString().padStart(2, '0')}:${dur.minutes().toString().padStart(2, '0')}:${dur.seconds().toString().padStart(2, '0')}`;
    return formattedTime;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'client_id', headerName: localeFormat('Client'), width: 150,
    },
    { field: 'activity_id', headerName: localeFormat('Activity'), width: 150 },
    {
      field: 'date', headerName: localeFormat('Date'), width: 110,
      valueGetter: (params) =>
        `${dayjs(params.row.date).format(localeFormat('dateFormat')) || ''}`,
    },
    {
      field: 'start_time',
      headerName: localeFormat('Start time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.start_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'end_time',
      headerName: localeFormat('End time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.end_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'time_cost_minutes',
      headerName: localeFormat('Time spent'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        getDuration(params.row.time_cost_minutes),
    },
    {
      field: 'price',
      headerName: localeFormat('Cost (€)'),
      type: 'number',
      width: 90,
    },
    {
      field: 'edit',
      headerName: localeFormat('Edit'),
      type: 'actions',
      getActions: (params) => [
        editItem !== null ? (
          < GridActionsCellItem icon={< EditIcon />} onClick={() => editItem(params.row)} label="Edit" />
        ) : <></>,
      ],
      width: 70,
    },
    {
      field: 'again',
      headerName: localeFormat('Again'),
      type: 'actions',
      getActions: (params) => [
        againItem !== null ? (
          <GridActionsCellItem icon={<FileCopyIcon />} onClick={() => againItem(params.row)} label="Again" />
        ) : <></>,
      ],
      width: 70,
    },
  ];

  const calcTotal = () => {
    let totalAmount = 0;
    let totalTime = 0;
    for (let i = 0; i < data.length; i++) {
      totalAmount += parseFloat(data[i].price) || 0; // Safely handle missing or invalid price
      totalTime += parseFloat(data[i].time_cost_minutes) || 0; // Safely handle missing or invalid time
    }
    return { totalAmount, totalTime };
  }

  const CustomFooter = (props) => {
    let { totalAmount, totalTime } = calcTotal();
    if (totalAmount > 0) {
      totalAmount = totalAmount.toFixed(2)
    }
    if (totalAmount > 0) {
      totalTime = getDuration(totalTime);
    }
    // console.log(totalAmount);
    return (
      <>
        <div style={{ padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ccc' }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <strong>{localeFormat("Total Time")}:</strong> {totalTime}
            </Grid>
            <Grid item md={3}>
              <strong>{localeFormat("Total Amount")}:</strong> €{totalAmount}
            </Grid>
          </Grid>
        </div>
        <GridPagination {...props} />
      </>
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        slots={
          exportButton !== null ? { toolbar: CustomToolbar, footer: (props) => <CustomFooter {...props} /> } : ''
        }
        pageSizeOptions={[10, 25, 100]}
      // checkboxSelection
      />
    </div>
  );
}

export default DataTable;